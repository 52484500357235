import { Box, Text, Button, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useProposalStore } from "@/contexts/useProposalStore";
import { format } from "date-fns";
import { pixKeyFormatter, pixKeyTypeLabels } from "@/utils/pixUtils";
import { cpfFormatter } from "@/utils/cpfFormatter";
import TextDisplay from "@/components/TextDisplay";
import { toZonedTime } from "date-fns-tz";
import { useConditionalBackground } from "@/contexts/useConditionalBackground ";

export default function PersonalData() {
  const { proposal } = useProposalStore();
  const navigate = useNavigate();
  const backgroundStyle = useConditionalBackground();
  const { shorthash } = useParams<{ shorthash: string }>();

  if (!proposal || !proposal.NaturalPerson) {
    return null;
  }

  const { name, document, birthDate, motherName, address } =
    proposal.NaturalPerson;
  const { BankDetail } = proposal;

  const handleConfirm = () => {
    navigate(`/${shorthash}/confirmacao`);
  };

  return (
    <Box
      minWidth="100vw"
      minHeight="100vh"
      {...backgroundStyle}
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      px={4}
    >
      <VStack
        mt="3rem"
        spacing={6}
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        maxWidth="400px"
        align="start"
        color="gray.800"
        fontFamily="Lexend Deca"
      >
        <Text
          fontSize="xl"
          fontWeight="bold"
          color="primary"
          textAlign="center"
          width="100%"
        >
          Dados Pessoais
        </Text>

        <Box width="100%">
          <Text fontSize="lg" fontWeight="medium" color="gray.600">
            Dados Básicos
          </Text>
          <Box bg="gray.50" padding="4" borderRadius="md" boxShadow="sm" mt="2">
            <VStack align="start" spacing="3">
              <TextDisplay label="Nome Completo" value={name} />
              <TextDisplay label="CPF" value={cpfFormatter(document)} />
              <TextDisplay
                label="Data de Nascimento"
                value={
                  birthDate
                  ? format(toZonedTime(birthDate, "UTC"), "dd/MM/yyyy")
                    : "Não informado"
                }
              />
              <TextDisplay label="Nome da Mãe" value={motherName} />
            </VStack>
          </Box>
        </Box>

        {address && (
          <Box width="100%">
            <Text fontSize="lg" fontWeight="medium" color="gray.600">
              Endereço
            </Text>
            <Box
              bg="gray.50"
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              mt="2"
            >
              <VStack align="start" spacing="3">
                <TextDisplay label="CEP" value={address.zipCode} />
                <TextDisplay label="Rua" value={address.addressName} />
                <TextDisplay label="Número" value={address.number} />
                <TextDisplay label="Bairro" value={address.district} />
                <TextDisplay label="Cidade" value={address.city} />
                <TextDisplay label="Estado" value={address.uf} />
                <TextDisplay label="Complemento" value={address.complement} />
              </VStack>
            </Box>
          </Box>
        )}

        {BankDetail && (
          <Box width="100%">
            <Text fontSize="lg" fontWeight="medium" color="gray.600">
              Financeiro
            </Text>
            <Box
              bg="gray.50"
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              mt="2"
            >
              <VStack align="start" spacing="3">
                <TextDisplay
                  label="Chave Pix através de:"
                  value={pixKeyTypeLabels[BankDetail.pixKeyType]}
                />

                {["PHONE", "EMAIL", "DOCUMENT"].includes(
                  BankDetail.pixKeyType
                ) && (
                  <TextDisplay
                    label="Chave Pix"
                    value={pixKeyFormatter(
                      BankDetail.pixKeyType,
                      BankDetail.pixKey
                    )}
                  />
                )}

                {BankDetail.pixKeyType === "BANK_ACCOUNT" && (
                  <>
                    <TextDisplay label="Banco" value={BankDetail.Bank?.name} />
                    <TextDisplay label="Agência" value={BankDetail.agency} />
                    <TextDisplay label="Conta" value={BankDetail.account} />
                    <TextDisplay
                      label="Dígito"
                      value={BankDetail.accountDigit}
                    />
                  </>
                )}
              </VStack>
            </Box>
          </Box>
        )}

        <Button
          onClick={handleConfirm}
          colorScheme="primary"
          width="100%"
          borderRadius="full"
          py="6"
          mt="6"
        >
          Confirmar Dados
        </Button>
      </VStack>
    </Box>
  );
}
