import { FileUpload } from '@/components/FileUpload';
import { useConditionalBackground } from '@/contexts/useConditionalBackground ';
import { Box, VStack, Text } from '@chakra-ui/react';

export default function DigitalDocumentUploadPage() {
  const backgroundStyle = useConditionalBackground();
  return (
    <Box
      minWidth="100vw"
      minHeight="100vh"
      {...backgroundStyle}
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      px={4}
    >
      <VStack
        mt="3rem"
        spacing={6}
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        maxWidth="400px"
        align="center"
      >
        <Text fontSize="xl" fontWeight="bold" color="primary" textAlign="center">
          Enviar Documento Digital
        </Text>
        <FileUpload label="Selecionar Arquivo" onUploadComplete={(file) => console.log(`Upload concluído para: ${file}`)} />
      </VStack>
    </Box>
  );
}
