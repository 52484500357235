import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Box,
  Input,
  chakra,
  FormControl,
  FormErrorMessage,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, Field, FieldProps } from "formik";
import { CpfValidation } from "./CpfValidation";
import { cpfFormatter } from "@/utils/cpfFormatter";
import { useProposalStore } from "@/contexts/useProposalStore";
import { getLastProposalByDocumentId } from "@/services/proposal.service";
import { useNavigate, useParams } from "react-router-dom";
import { Proposal } from "@/models/proposal.model";

interface LoginFormValues {
  cpf: string;
}

const ChakraForm = chakra("form");

const LoginForm: React.FC = () => {
  const { setProposal, clearProposal } = useProposalStore();
  const { shorthash } = useParams<{ shorthash: string }>();
  const toast = useToast();
  const navigate = useNavigate();

  const [proposalData, setProposalData] = useState<Proposal | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initialValues: LoginFormValues = {
    cpf: "",
  };

  useEffect(() => {
    const fetchProposalData = async () => {
      try {
        const data = await getLastProposalByDocumentId(shorthash);
        clearProposal();

        setProposalData(data);

        if (!data) {
          toast({
            title: "Nenhuma proposta encontrada",
            description: "Não foi possível encontrar os dados da proposta.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados da proposta:", error);
        toast({
          title: "Erro",
          description: "Erro ao buscar dados da proposta.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchProposalData();
  }, [shorthash]);

  const handleSubmit = async (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>
  ) => {
    try {
      if (!proposalData) {
        toast({
          title: "Dados não carregados",
          description: "Os dados da proposta ainda não foram carregados.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const enteredCpf = values.cpf.replace(/\D/g, "");
      const proposalCpf = proposalData.NaturalPerson!.document.replace(
        /\D/g,
        ""
      );

      if (enteredCpf !== proposalCpf) {
        toast({
          title: "CPF incorreto",
          description: "O CPF informado não corresponde ao CPF da proposta.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setProposal(proposalData);

      navigate(`/${shorthash}/dados-pessoais`);
    } catch (error) {
      console.error("Erro no login:", error);
      toast({
        title: "Erro ao efetuar login",
        description: "Ocorreu um erro ao validar o CPF.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <Flex
        width="100%"
        minHeight="50vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor="transparent"
        color="white"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex
      width="100%"
      justifyContent="center"
      backgroundColor="transparent"
      flexDirection="column"
      color="white"
      alignItems="center"
      fontFamily="Lexend Deca"
      paddingX="5%"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={CpfValidation}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, handleSubmit }) => (
          <ChakraForm width="100%" onSubmit={handleSubmit}>
            <Box width="100%" paddingBottom="2rem">
              <FormControl isInvalid={!!errors.cpf && touched.cpf}>
                <Field name="cpf">
                  {({ field, form }: FieldProps) => (
                    <Input
                      {...field}
                      id="cpf"
                      placeholder="Digite seu CPF"
                      type="tel"
                      onChange={(e) =>
                        form.setFieldValue("cpf", cpfFormatter(e.target.value))
                      }
                      fontSize="lg"
                      width="100%"
                      variant="flushed"
                      focusBorderColor="primary"
                      textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
                      _placeholder={{ color: "white" }}
                      paddingY="1rem"
                    />
                  )}
                </Field>
                <FormErrorMessage marginTop="0.5rem">
                  {errors.cpf}
                </FormErrorMessage>
              </FormControl>
              <Button
                backgroundColor="white"
                width="100%"
                color="primary"
                borderRadius="24px"
                type="submit"
                height="3rem"
                isDisabled={isSubmitting}
                marginTop="1.5rem"
                fontSize="lg"
                paddingY="1.5rem"
                _hover={{ backgroundColor: "gray.100" }}
              >
                Inicie sua assinatura digital
              </Button>
            </Box>
          </ChakraForm>
        )}
      </Formik>
    </Flex>
  );
};

export default LoginForm;
