import axiosInstance from "./axiosInstance";

interface DocumentUrlResponse {
  sasUrl: string;
  fileUrl: string;
}

export const getDocumentUrl = async (
  fileName: string
): Promise<DocumentUrlResponse> => {
  try {
    const urlRes = await axiosInstance.post('/api/generate-upload-url', {
      fileName: fileName,
    });

    return urlRes.data;
  } catch (error) {
    console.error("Erro ao obter a URL do documento:", error);
    throw error;
  }
};
