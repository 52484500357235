import { Flex, Text } from "@chakra-ui/react";
import { useConditionalBackground } from "@/contexts/useConditionalBackground ";
import Logo from "../Logo";

export default function LargeScreenBlock() {
  const backgroundStyle = useConditionalBackground();
  return (
    <Flex
      minW="100vw"
      minH="100vh"
      {...backgroundStyle}
      flexDirection="column"
      align="center"
      pt="10%"
      color="white"
    >
      <Flex w="100%" justify="center">
        <Logo variant="primary" alt="Logo" boxSize="10%" mb="8" />
      </Flex>
      <Text fontSize="2xl">
        Esse sistema só pode ser acessado através de{" "}
        <Text as="span" fontWeight="bold">
          Celular
        </Text>{" "}
        ou{" "}
        <Text as="span" fontWeight="bold">
          Tablet
        </Text>
        .
      </Text>
    </Flex>
  );
}
