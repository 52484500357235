//@ts-ignore
import { IDPaySDK } from 'idpay-b2b-sdk';

let isInitialized = false;

interface InitializeSDKParams {
  token: string;
  transactionId: string;
  onFinish: (process: any) => void;
}

export function initializeSDK(params: InitializeSDKParams): void {
  const { token, transactionId, onFinish } = params;

  if (!isInitialized) {
    console.log('Initializing SDK');
    try {
      IDPaySDK.init({
        type: 'IFRAME',
        token: token,
      });

      IDPaySDK.open({
        transactionId: transactionId,
        token: token,
        onFinish: onFinish,
      });

      isInitialized = true;
    } catch (error) {
      console.error('Error initializing SDK:', error);
      isInitialized = false; // Reset in case of error
    }
  } else {
    console.log('SDK already initialized');
  }
}

// Handle HMR
if (import.meta && import.meta.hot) {
  import.meta.hot.accept(() => {
    // Do nothing
  });
}

