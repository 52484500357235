import { WhiteLabel } from "@/models/whiteLabel.model";
import { create } from "zustand";

import defaultPrimaryLogo from "@/assets/loginLogo.png";
import defaultSecondaryLogo from "@/assets/GranaTechWhite.png";

interface WhiteLabelState {
  whiteLabel: WhiteLabel | null;
  setWhiteLabel: (whiteLabel: WhiteLabel | null) => void;
  getAssets: () => {
    name: string;
    primaryColor: string;
    secondaryColor: string;
    primaryLogo: string;
    secondaryLogo: string;
    favicon: string;
    pageTitle: string;
  };
}

const defaultAssets = {
  name: "GranaTech",
  primaryColor: "#48BB78",
  secondaryColor: "#2c5282", // #3182ce
  primaryLogo: defaultPrimaryLogo,
  secondaryLogo: defaultSecondaryLogo,
  favicon: "/favicon.png",
  pageTitle: "GranaTech"
};

export const useWhiteLabelStore = create<WhiteLabelState>((set, get) => ({
  whiteLabel: null,
  setWhiteLabel: (whiteLabel) => set({ whiteLabel }),
  getAssets: () => {
    const { whiteLabel } = get();
    return {
      name: whiteLabel?.name || defaultAssets.name,
      primaryColor: whiteLabel?.primaryColor || defaultAssets.primaryColor,
      secondaryColor:
        whiteLabel?.secondaryColor || defaultAssets.secondaryColor,
      primaryLogo: whiteLabel?.primaryLogo || defaultAssets.primaryLogo,
      secondaryLogo: whiteLabel?.secondaryLogo || defaultAssets.secondaryLogo,
      favicon: whiteLabel?.favicon || defaultAssets.favicon,
      pageTitle: whiteLabel?.pageTitle || whiteLabel?.name || defaultAssets.pageTitle,
    };
  },
}));
