const BrlCurrencyFormatter = (
  value: string | number | null | undefined
): string => {
  const parsedValue = typeof value === "string" ? parseFloat(value) : value;

  return parsedValue && !isNaN(parsedValue)
    ? new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(parsedValue)
    : "Valor não disponível";
};

export { BrlCurrencyFormatter };
