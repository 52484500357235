import { useState } from "react";
import {
  Box,
  VStack,
  Button,
  Text,
  Link,
  Checkbox,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import TextDisplay from "@/components/TextDisplay";
import { useProposalStore } from "@/contexts/useProposalStore";
import { BrlCurrencyFormatter } from "@/utils/BrlCurrencyFormatter";

// @ts-ignore
import { IDPaySDK } from "idpay-b2b-sdk";
import PdfViewer from "@/components/PdfViewer";
import { useConditionalBackground } from "@/contexts/useConditionalBackground ";

export default function Confirmation() {
  const backgroundStyle = useConditionalBackground();
  const navigate = useNavigate();
  const { proposal } = useProposalStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAgreed, setIsAgreed] = useState(false);

  if (!proposal) {
    navigate("/404");
    return null;
  }

  const { chosenFinancialInfo, Contract } = proposal;
  const productName = chosenFinancialInfo?.Product?.name || "Não informado";
  const pdfUrl = Contract?.contractUrl ?? "";
  const releasedAmount =
    chosenFinancialInfo?.releasedAmount != null
      ? BrlCurrencyFormatter(chosenFinancialInfo.releasedAmount)
      : "Não informado";
  const issueAmount =
    chosenFinancialInfo?.issueAmount != null
      ? BrlCurrencyFormatter(chosenFinancialInfo.issueAmount)
      : "Não informado";
  const monthlyRate =
    chosenFinancialInfo?.monthlyRate != null
      ? `${(chosenFinancialInfo.monthlyRate * 100).toFixed(2)}%`
      : "Não informado";
  const monthlyCET =
    proposal.monthlyCET != null
      ? `${(proposal.monthlyCET * 100).toFixed(2)}%`
      : "Não informado";
  const anticipatedYears =
    proposal.anticipatedYears != null
      ? `${proposal.anticipatedYears} anos`
      : "Não informado";

  return (
    <Box
      minHeight="100vh"
      {...backgroundStyle}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px={4}
      py={6}
    >
      <VStack
        spacing={6}
        bg="white"
        p={6}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        maxWidth="400px"
        align="start"
        color="gray.800"
        fontFamily="Lexend Deca"
      >
        <Text
          fontSize="xl"
          fontWeight="bold"
          color="primary"
          textAlign="center"
          width="100%"
        >
          Confirmação de Dados
        </Text>

        <Box width="100%">
          <Text fontSize="lg" fontWeight="medium" color="gray.600" mb={2}>
            Informações Financeiras
          </Text>
          <Box bg="gray.50" padding="4" borderRadius="md" boxShadow="sm">
            <VStack align="start" spacing="3">
              <TextDisplay label="Produto" value={productName} />
              <TextDisplay label="Valor Liberado" value={releasedAmount} />
              <TextDisplay label="Saldo Bloqueado" value={issueAmount} />
              <TextDisplay label="Taxa AM" value={monthlyRate} />
              <TextDisplay label="CET AM" value={monthlyCET} />
              <TextDisplay
                label="Anos de Antecipação"
                value={anticipatedYears}
              />
            </VStack>
          </Box>
        </Box>

        <VStack width="100%" spacing={4} align="start">
          <Link
            color="primary"
            onClick={onOpen}
            textDecoration="underline"
            fontWeight="medium"
            display="inline-flex"
            alignItems="center"
          >
            <Box as="span" mr={2}>
              📄
            </Box>
            Ler contrato inteiro
          </Link>
          <Checkbox
            isChecked={isAgreed}
            onChange={(e) => setIsAgreed(e.target.checked)}
            color="primary"
            size="lg"
            spacing={3}
          >
            <Text fontSize="sm">Concordo com os termos do contrato</Text>
          </Checkbox>
        </VStack>

        <Button
          onClick={() => {
            window.location.href =
              import.meta.env.VITE_ENV === "local"
                ? `https://cadastro.uat.unico.app/process/${
                    proposal.UnicoData!.unicoId
                  }`
                : `https://cadastro.unico.app/process/${
                    proposal.UnicoData!.unicoId
                  }`;
          }}
          colorScheme="primary"
          width="100%"
          borderRadius="full"
          py="6"
          mt="4"
          fontWeight="bold"
          disabled={!isAgreed}
        >
          Assinar
        </Button>
      </VStack>

      <PdfViewer isOpen={isOpen} onClose={onClose} pdfUrl={pdfUrl} />
    </Box>
  );
}
