import { useConditionalBackground } from "@/contexts/useConditionalBackground ";
import { Proposal } from "@/models/proposal.model";
import { getLastProposalByDocumentId } from "@/services/proposal.service";
import {
  Box,
  VStack,
  Heading,
  Link,
  useToast,
  Flex,
  Spinner,
  Text,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaCheckCircle, FaDownload, FaRegCopy } from "react-icons/fa";
import { useParams } from "react-router-dom";

export default function ContractSuccess() {
  const backgroundStyle = useConditionalBackground();
  const { shorthash } = useParams<{ shorthash: string }>();
  const [proposalData, setProposalData] = useState<Proposal | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();

  const handleCopyCurrentUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    toast({
      title: "Copiado",
      description: "Link copiado para a área de transferência.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const fetchProposalData = async () => {
    try {
      const data = await getLastProposalByDocumentId(shorthash);
      setProposalData(data);
      if (!data) {
        toast({
          title: "Nenhuma proposta encontrada",
          description: "Não foi possível encontrar os dados da proposta.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Erro ao buscar dados da proposta:", error);
      toast({
        title: "Erro",
        description: "Erro ao buscar dados da proposta.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProposalData();
    const interval = setInterval(fetchProposalData, 30000);
    return () => clearInterval(interval);
  }, [shorthash]);

  if (isLoading) {
    return (
      <Flex
        width="100%"
        minHeight="50vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor="transparent"
        color="white"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box
      minWidth="100vw"
      minHeight="100vh"
      {...backgroundStyle}
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      px={4}
      fontFamily="Lexend Deca"
    >
      <VStack
        mt="3rem"
        spacing={8}
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        maxWidth="400px"
        align="center"
        color="gray.800"
      >
        <Box bg="green.100" color="green.500" p={4} borderRadius="full">
          <FaCheckCircle size={64} />
        </Box>
        <Heading
          as="h1"
          size="xl"
          textAlign="center"
          color="primary"
          fontWeight="bold"
        >
          Assinatura realizada!
        </Heading>
        <Box bg="gray.50" borderRadius="md" boxShadow="sm" width="100%">
          {proposalData?.Contract?.signedContractUrl ? (
            <Link
              href={proposalData.Contract.signedContractUrl}
              download
              color="primary"
              fontSize="lg"
              fontWeight="bold"
              display="flex"
              alignItems="center"
              justifyContent="center"
              _hover={{ textDecoration: "underline" }}
            >
              <FaDownload style={{ marginRight: "8px" }} />
              Baixar contrato assinado
            </Link>
          ) : (
            <Flex
              direction="column"
              align="center"
              bg="white"
              borderRadius="md"
              p={4}
            >
              <Text fontSize="md" textAlign="center" mb={4}>
                Seu contrato assinado está sendo processado, aguarde alguns
                minutos. Acompanhe seu contrato nesse link:
              </Text>
              <Button
                leftIcon={<FaRegCopy />}
                onClick={handleCopyCurrentUrl}
                size="sm"
                variant="outline"
                mt={2}
              >
                Copiar Link
              </Button>
            </Flex>
          )}
        </Box>
      </VStack>
    </Box>
  );
}
