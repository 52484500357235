import { useTheme } from "@chakra-ui/react";
import { useWhiteLabelStore } from "./whiteLabelContext";

export const useConditionalBackground = () => {
  const { whiteLabel } = useWhiteLabelStore();
  const theme = useTheme();

  return whiteLabel
    ? { bg: "primary" }
    : {
        bgGradient: `linear(to-r, ${theme.colors.primary}, ${theme.colors.secondary})`,
      };
};