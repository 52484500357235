export const useSetBrowserPage = (favicon: any, title: string) => {
  let link: HTMLLinkElement | null =
    document.querySelector("link[rel*='icon']");

  document.title = title;

  if (link) {
    link.type = "image/svg+xml";
    link.href = favicon;
  } else {
    console.warn("Favicon link not found in the document head");
  }
};
