import { useState } from 'react';
import { Button, Input, Box, Text, Icon, useToast } from '@chakra-ui/react';
import { FiFile } from 'react-icons/fi';
import axios from 'axios';
import { getDocumentUrl } from '@/services/fileUrl.service';
import { useNavigate, useParams } from 'react-router-dom';

interface FileUploadProps {
  label: string;
  onUploadComplete?: (fileUrl: string) => void;
}

export function FileUpload({ label, onUploadComplete }: FileUploadProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate()
  const { shorthash } = useParams<{ shorthash: string }>()
  const toast = useToast();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFile(file);
      toast({
        title: 'Documento selecionado!',
        description: `Arquivo ${file.name} está pronto para envio.`,
        status: 'info',
        duration: 2500,
        isClosable: true,
        position: 'top',
      });
    }
  };

  const handleUpload = async () => {
    navigate(`/${shorthash}/upload-documentos`)
    if (!selectedFile) return;
    setIsUploading(true);
  
    try {
      const response = await getDocumentUrl(selectedFile.name);
  
      const { sasUrl, fileUrl } = response;
  
      await axios.put(sasUrl, selectedFile, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': selectedFile.type,
        },
      });
  
      toast({
        title: 'Upload realizado com sucesso!',
        description: 'Seu documento foi enviado.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
  
      onUploadComplete?.(fileUrl);
      setSelectedFile(null);
    } catch (error) {
      console.error('Erro ao fazer upload do arquivo:', error);
      // toast({
      //   title: 'Erro ao enviar o arquivo.',
      //   description: 'Por favor, tente novamente.',
      //   status: 'error',
      //   duration: 3000,
      //   isClosable: true,
      //   position: 'top',
      // });
    } finally {
      setIsUploading(false);
    }
  };
  

  return (
    <Box width="100%" textAlign="center">
      <Button
        colorScheme="teal"
        variant="outline"
        width="100%"
        borderRadius="full"
        fontSize="lg"
        onClick={() => document.getElementById(label)?.click()}
        isDisabled={isUploading}
      >
        {label}
      </Button>
      <Input
        type="file"
        id={label}
        display="none"
        onChange={handleFileChange}
        accept=".pdf,.jpg,.jpeg,.png"
      />
      {selectedFile && (
        <Box mt="4">
          <Text fontSize="md" color="gray.600" mb="2">
            Arquivo selecionado: {selectedFile.name}
          </Text>
          <Icon as={FiFile} boxSize={12} color="gray.500" />
          <Button
            colorScheme="teal"
            mt="4"
            width="100%"
            borderRadius="full"
            fontSize="lg"
            onClick={handleUpload}
            isDisabled={isUploading}
          >
            Enviar Arquivo
          </Button>
        </Box>
      )}
    </Box>
  );
}
