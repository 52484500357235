import { Proposal } from '@/models/proposal.model';
import { create } from 'zustand';

interface ProposalState {
  proposal: Proposal | null;
  setProposal: (proposal: Proposal) => void;
  clearProposal: () => void;
}

export const useProposalStore = create<ProposalState>((set) => ({
  proposal: null,
  setProposal: (proposal) => set({ proposal }),
  clearProposal: () => set({ proposal: null }),
}));
