export const phoneFormatter = (value: string) => {
  const numbers = value.replace(/\D/g, "");

  if (!numbers) return "";

  const areaCode = numbers.slice(0, 2);
  let formatted = `(${areaCode}`;

  const rest = numbers.slice(2);

  if (rest.length > 0) {
    formatted += ') ';

    if (rest.length <= 4) {
      formatted += rest;
    } else if (rest.length <= 8) {
      formatted += `${rest.slice(0, 4)}-${rest.slice(4)}`;
    } else {
      formatted += `${rest.slice(0, 5)}-${rest.slice(5, 9)}`;
    }
  }

  return formatted;
};
