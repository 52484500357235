import { useWhiteLabelStore } from "@/contexts/whiteLabelContext";
import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const createTheme = () => {
  const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
  };

  const getAssets = useWhiteLabelStore.getState().getAssets;
  const { primaryColor, secondaryColor } = getAssets();

  const theme = extendTheme({
    config,
    colors: {
      primary: primaryColor,
      secondary: secondaryColor,
    },
    fonts: {
      heading: "Lexend Deca, sans-serif",
      body: "Lexend Deca, sans-serif",
    },
    components: {
      Button: {
        baseStyle: {
          fontWeight: "bold",
        },
        variants: {
          solid: (props: { colorScheme: string }) => ({
            bg: props.colorScheme,
            color: "white",
            _hover: {
              bg: props.colorScheme,
              filter: "brightness(90%)",
            },
          }),
          outline: (props: { colorScheme: string }) => ({
            border: "2px solid",
            borderColor: props.colorScheme,
            color: props.colorScheme,
          }),
        },
      },
    },
    styles: {
      global: {
        body: {
          overflowX: "hidden",
        },
      },
    },
  });

  return theme;
};

export default createTheme;
