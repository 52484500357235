import { Flex, Text } from "@chakra-ui/react";
import LoginForm from "@/components/LoginForm";
import { useConditionalBackground } from "@/contexts/useConditionalBackground ";
import Logo from "@/components/Logo";

export default function Login() {
  const backgroundStyle = useConditionalBackground();
  return (
    <Flex
      minW="100vw"
      minH="100vh"
      {...backgroundStyle}
      flexDirection="column"
      align="center"
      pt="10%"
    >
      <Flex w="100%" justify="center">
        <Logo variant="primary" alt="Logo" boxSize="50%" mt="10%" />
      </Flex>
      <Flex w="100%" justify="center">
        <Text
          fontSize="xl"
          color="white"
          textAlign="center"
          mt="4rem"
          px="4"
          maxWidth="80%"
        >
          Falta pouco para concluir seu contrato.
        </Text>
      </Flex>
      <Flex w="100%" justify="center" mt="8">
        <LoginForm />
      </Flex>
    </Flex>
  );
}
