import { Flex, Text } from "@chakra-ui/react";
import { useConditionalBackground } from "@/contexts/useConditionalBackground ";
import Logo from "@/components/Logo";

export default function NotFoundPage() {
  const backgroundStyle = useConditionalBackground();
  return (
    <Flex
      minW="100vw"
      minH="100vh"
      {...backgroundStyle}
      flexDirection="column"
      align="center"
      pt="15%"
      color="white"
    >
      <Flex w="100%" justify="center">
        <Logo variant="primary" alt="Logo" boxSize="50%" mb="8" />
      </Flex>
      <Text fontSize="xl" fontWeight="bold" textAlign="center">
        Essa página não existe.
      </Text>
      <Text fontSize="xl" textAlign="center" mt={2}>
        Peça para o seu Corban mandar o link novamente.
      </Text>
    </Flex>
  );
}
