import { useWhiteLabelStore } from "@/contexts/whiteLabelContext";
import { Image, ImageProps } from "@chakra-ui/react";

interface LogoProps extends Omit<ImageProps, "src"> {
  variant: "primary" | "secondary";
}

export default function Logo({ variant, ...props }: LogoProps) {
  const getAssets = useWhiteLabelStore.getState().getAssets;
  const { primaryLogo, secondaryLogo } = getAssets();

  const src = variant === "primary" ? primaryLogo : secondaryLogo;

  return <Image src={src} alt="Logo" {...props} />;
}