export const getSlugFromUrl = (): string | null => {
  const hostname = window.location.hostname;
  const excludedHosts = [
    "dev.granatech.com.br",
    "app.granatech.com.br",
    "granatech.com.br",
    "www.granatech.com.br",
    "localhost",
    "https://dev.minhabiometria.com.br",
    "https://v2.minhabiometria.com.br",
  ];

  if (excludedHosts.includes(hostname)) {
    return null;
  }

  return hostname.split(".")[0];
};
