import React from "react";
import { Flex, Box, useColorModeValue, Icon, Text } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaUser, FaCheckCircle, FaCamera, FaUpload } from "react-icons/fa";

interface TabItem {
  label: string;
  path: string;
  icon: React.ElementType;
  additionalPaths?: string[];
}

const tabs: TabItem[] = [
  { label: "Dados", path: "dados-pessoais", icon: FaUser },
  { label: "Confirmação", path: "confirmacao", icon: FaCheckCircle },
  { label: "Selfie", path: "selfie", icon: FaCamera },
  {
    label: "Documentos",
    path: "upload-documentos",
    icon: FaUpload,
    additionalPaths: ["documento-digital", "outros-documentos"],
  },
];

export default function TabNavigator() {
  const navigate = useNavigate();
  const location = useLocation();

  const pathSegments = location.pathname.split("/");
  const currentPath = pathSegments[pathSegments.length - 1];

  const getCurrentIndex = () => {
    const index = tabs.findIndex(
      (tab) =>
        tab.path === currentPath || tab.additionalPaths?.includes(currentPath)
    );
    return index !== -1 ? index : 0;
  };

  const currentIndex = getCurrentIndex();

  const handleTabClick = (index: number, path: string) => {
    if (index <= currentIndex) {
      navigate(path);
    }
  };

  const tabColor = useColorModeValue("gray.600", "gray.300");
  const bgColor = useColorModeValue("white", "gray.800");

  return (
    <Flex
      as="nav"
      bg={bgColor}
      borderTopWidth="1px"
      boxShadow="0 -2px 10px rgba(0, 0, 0, 0.2)"
      py={3}
      px={2}
      justify="space-between"
      align="center"
      width="100%"
      maxWidth="100%"
      overflowX="auto"
      gap={2}
      css={{
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none'
      }}
    >
      {tabs.map((tab, index) => (
        <Box
          key={tab.path}
          as="button"
          onClick={() => handleTabClick(index, tab.path)}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={2}
          px={1}
          flex="1"
          minWidth="auto"
          height="100%"
          color={index === currentIndex ? "primary" : tabColor}
          fontWeight={index === currentIndex ? "bold" : "normal"}
          opacity={index > currentIndex ? 0.5 : 1}
          transition="all 0.2s ease"
          _hover={{ color: "primary" }}
          disabled={index > currentIndex}
        >
          <Icon as={tab.icon} boxSize={5} mb={1.5} />
          <Text 
            fontSize="sm" 
            noOfLines={1} 
            textAlign="center"
            lineHeight="1.2"
            maxW="100%"
            px={1}
          >
            {tab.label}
          </Text>
        </Box>
      ))}
    </Flex>
  );
}