import { Box, ChakraProvider, Spinner, VStack, Text } from "@chakra-ui/react";
import GranaTech from "./routes";
import { useState, useEffect } from "react";
import { getSlugFromUrl } from "./utils/getSlugFromUrl";
import { useWhiteLabelStore } from "./contexts/whiteLabelContext";
import { fetchWhiteLabel } from "./services/whiteLabel.service";
import { useSetBrowserPage } from "./contexts/useSetBrowserPage";
import createTheme from "./theme/theme";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { setWhiteLabel } = useWhiteLabelStore();
  const getAssets = useWhiteLabelStore((state) => state.getAssets);
  const slug = getSlugFromUrl();

  useEffect(() => {
    const loadWhiteLabel = async () => {
      setIsLoading(true);
      try {
        const data = await fetchWhiteLabel(slug);
        setWhiteLabel(data);
      } catch (error) {
        console.error("Error loading white label data:", error);
        setWhiteLabel(null);
      } finally {
        setIsLoading(false);
      }
    };

    loadWhiteLabel();

  }, [setWhiteLabel, slug]);

  const { favicon, pageTitle } = getAssets();
  useSetBrowserPage(favicon, pageTitle);

  const theme = createTheme();

  if (isLoading) {
    return (
      <ChakraProvider theme={theme}>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <VStack spacing={4}>
            <Spinner size="xl" color="primary" thickness="4px" speed="0.65s" />
            <Text fontSize="xl" fontWeight="medium">
              Carregando...
            </Text>
          </VStack>
        </Box>
      </ChakraProvider>
    );
  } else {
    return (
      <ChakraProvider theme={theme}>
        <GranaTech />
      </ChakraProvider>
    );
  }
}
