import { useConditionalBackground } from '@/contexts/useConditionalBackground ';
import { Box, Text, Button, VStack } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

export default function DocumentUpload() {
  const backgroundStyle = useConditionalBackground();
  const navigate = useNavigate();
  const { shorthash } = useParams<{ shorthash: string }>();

  const documentOptions = [
    { label: 'CNH' },
    { label: 'RG' },
    { label: 'RG Novo' },
    { label: 'Documento Digital' },
    { label: 'Outros Documentos' },
  ];

  const handleOptionClick = (label: string) => {
    switch (label) {
      case 'Documento Digital':
        navigate(`/${shorthash}/documento-digital`);
        break;
      case 'Outros Documentos':
        navigate(`/${shorthash}/outros-documentos`);
        break;
      default:
        navigate(`/${shorthash}/contrato`);
    }
  };

  return (
    <Box
      minWidth="100vw"
      minHeight="100vh"
      {...backgroundStyle}
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      px={4}
    >
      <VStack
        mt="3rem"
        spacing={6}
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        maxWidth="400px"
        align="center"
      >
        <Text fontSize="xl" fontWeight="bold" color="primary" textAlign="center">
          Escolha seu documento
        </Text>

        {documentOptions.map((option) => (
          <Button
            key={option.label}
            colorScheme="teal"
            variant="outline"
            width="100%"
            borderRadius="full"
            fontSize="lg"
            onClick={() => handleOptionClick(option.label)}
          >
            {option.label}
          </Button>
        ))}
      </VStack>
    </Box>
  );
}
