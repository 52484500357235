export function validateCPF(cpf: string): boolean {
    // Remove any non-digit character
    cpf = cpf.replace(/[^\d]+/g, '');

    // Check if the CPF has 11 digits or if all digits are the same
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
    }

    // Calculate the first check digit
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    let checkDigit1 = remainder > 9 ? 0 : remainder;

    // Calculate the second check digit
    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    let checkDigit2 = remainder > 9 ? 0 : remainder;

    // Verify if the check digits are correct
    return checkDigit1 === parseInt(cpf.charAt(9)) && checkDigit2 === parseInt(cpf.charAt(10));
}
