import { cpfFormatter } from "./cpfFormatter";
import { phoneFormatter } from "./phoneFormatter";

export const pixKeyFormatter = (
  type: string,
  key: string | null | undefined
): string => {
  if (!key) return "Não informado";

  switch (type) {
    case "DOCUMENT":
      return cpfFormatter(key);
    case "PHONE":
      return phoneFormatter(key);
    case "EMAIL":
      return key;
    default:
      return "Não informado";
  }
};

export const pixKeyTypeLabels: Record<string, string> = {
  PHONE: "Telefone",
  EMAIL: "E-mail",
  DOCUMENT: "CPF",
  BANK_ACCOUNT: "Conta Bancária",
};
