import { Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import PersonalData from "./pages/PersonalData";
import Confirmation from "./pages/Confirmation";
import DocumentUpload from "./pages/DocumentUpload";
import Selfie from "./pages/Selfie";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import OtherDocumentsUpload from "./pages/OtherDocumentsUpload";
import DigitalDocumentUpload from "./pages/DigitalDocumentUpload";
import ContractSuccess from "./pages/ContractSuccess";

export default function GranaTech() {
  return (
    <Routes>
      <Route path="/:shorthash" element={<MainLayout />}>
        <Route index element={<Login />} />
        <Route path="dados-pessoais" element={<PersonalData />} />
        <Route path="confirmacao" element={<Confirmation />} />
        <Route path="upload-documentos" element={<DocumentUpload />} />
        <Route path="documento-digital" element={<DigitalDocumentUpload />} />
        <Route path="outros-documentos" element={<OtherDocumentsUpload />} />
        <Route path="selfie" element={<Selfie />} />
      </Route>

      <Route path="/:shorthash/contrato" element={<ContractSuccess />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}
