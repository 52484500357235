import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

type PDFViewerProps = {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string;
};

const PDFViewer: React.FC<PDFViewerProps> = ({ isOpen, onClose, pdfUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent
        height="100vh"
        minHeight="100%"
        width="100%"
        borderRadius="none"
      >
        <ModalHeader fontSize="lg" fontWeight="bold" p={4}>
          Contrato
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Box
            height="100%"
            width="100%"
            overflowY="auto"
            p={4}
            bg="gray.100"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  width="100%"
                >
                  <Spinner size="lg" />
                  <Text color="gray.500" ml={2}>
                    Carregando documento...
                  </Text>
                </Box>
              }
              error={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  width="100%"
                >
                  <Text color="red.500">Erro ao carregar o PDF.</Text>
                </Box>
              }
            >
              {Array.from(new Array(numPages || 0), (_, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  width={window.innerWidth}
                />
              ))}
            </Document>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PDFViewer;
