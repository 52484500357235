import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import { Box, ChakraProps, Flex } from "@chakra-ui/react";
import { useProposalStore } from "@/contexts/useProposalStore";
import TabNavigator from "../TabNavigator";
import LargeScreenBlock from "../LargeScreenBlock";

export default function MainLayout() {
  const { shorthash } = useParams<{ shorthash: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { proposal } = useProposalStore();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isAuthenticated = !!proposal;
  const loginPath = `/${shorthash}`;
  const dadosPessoaisPath = `/${shorthash}/dados-pessoais`;
  const currentPath = location.pathname;

  useEffect(() => {
    if (!isAuthenticated && currentPath !== loginPath) {
      navigate(loginPath, { replace: true });
    }
    if (isAuthenticated && currentPath === loginPath) {
      navigate(dadosPessoaisPath, { replace: true });
    }
  }, [isAuthenticated, currentPath, loginPath, dadosPessoaisPath, navigate]);

  const showTabs = isAuthenticated && currentPath !== loginPath;

  if (!isMobileView) {
    return <LargeScreenBlock />;
  }

  const contentStyles: ChakraProps = {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    sx: {
      WebkitOverflowScrolling: 'touch',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      overscrollBehavior: 'none',
    },
  }

  return (
    <Flex 
      flexDirection="column" 
      height="100%" 
      width="100%" 
      position="fixed" 
      top={0} 
      left={0} 
      overflowY="hidden"
      overflowX="hidden"
    >
      {showTabs && (
        <Box flexShrink={0} width="100%">
          <TabNavigator />
        </Box>
      )}
      <Box {...contentStyles}>
        <Outlet />
      </Box>
    </Flex>
  )
}