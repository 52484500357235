import { WhiteLabel } from "@/models/whiteLabel.model";
import { AxiosError } from "axios";
import axiosInstance from "./axiosInstance";

export const fetchWhiteLabel = async (
  slug: string | null
): Promise<WhiteLabel | null> => {
  try {
    if (slug) {
      const response = await axiosInstance.get<WhiteLabel>("/api/whitelabel/", {
        params: { slug },
      });
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching white label data:", error);
    if (error instanceof AxiosError && error.response?.status === 404) {
      return null;
    }
    throw error;
  }
};

export const createWhiteLabel = async (data: any): Promise<WhiteLabel> => {
  try {
    const response = await axiosInstance.post<WhiteLabel>("/api/whitelabel", data);
    return response.data;
  } catch (error) {
    console.error("Error creating white label:", error);
    throw error;
  }
};

export const updateWhiteLabel = async (id: string, data: any): Promise<WhiteLabel> => {
  try {
    const response = await axiosInstance.put<WhiteLabel>(`/api/whitelabel/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating white label:", error);
    throw error;
  }
};


export const checkWhitelabel = async (
  slug: string | null
): Promise<WhiteLabel | null> => {
  try {
    if (slug) {
      const response = await axiosInstance.get<WhiteLabel>(`/api/whitelabel/${slug}/exists`);
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching white label data:", error);
    if (error instanceof AxiosError && error.response?.status === 404) {
      return null;
    }
    throw error;
  }
};
