import { Proposal } from "@/models/proposal.model";
import axiosInstance from "./axiosInstance";

export const getLastProposalByDocumentId = async (
  hash?: string
): Promise<Proposal | null> => {
  try {
    if(!hash) return null

    const proposalRes = await axiosInstance.get(
      `/api/proposal/unico/${hash}`
    );

    return proposalRes.data;
  } catch (error) {
    console.error("Erro ao obter o Proposta:", error);
    throw error;
  }
};