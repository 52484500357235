import { useEffect } from 'react';
import { useProposalStore } from '@/contexts/useProposalStore';
import { Box, Text } from '@chakra-ui/react';
import { initializeSDK } from '@/components/SdkSingleton';

export default function Selfie(): JSX.Element | null {
  const { proposal } = useProposalStore();

  useEffect(() => {
    console.log('useEffect called', {
      token: proposal?.UnicoData?.token,
      unicoId: proposal?.UnicoData?.unicoId,
    });

    if (proposal?.UnicoData?.token && proposal?.UnicoData?.unicoId) {
      initializeSDK({
        token: proposal.UnicoData.token,
        transactionId: proposal.UnicoData.unicoId,
        onFinish: (process: any) => {
          console.log('Process', process);
        },
      });
    }
  }, [proposal?.UnicoData?.token, proposal?.UnicoData?.unicoId]);

  if (!proposal?.UnicoData) {
    return null;
  }

  return (
    <Box padding="20px">
      <Text fontSize="2xl">Tirar Selfie</Text>
    </Box>
  );
}
