import { useNavigate, useParams } from 'react-router-dom';
import { Box, Text, VStack, Button } from '@chakra-ui/react';
import { useConditionalBackground } from '@/contexts/useConditionalBackground ';

export default function OtherDocumentsUpload() {
  const backgroundStyle = useConditionalBackground();
  const navigate = useNavigate();
  const { shorthash } = useParams<{ shorthash: string }>();
  const otherDocumentOptions = ['RNE', 'CPTS'];

  const handleButtonClick = () => {
    navigate(`/${shorthash}/documento-digital`);
  };

  return (
    <Box
      minWidth="100vw"
      minHeight="100vh"
      {...backgroundStyle}
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      px={4}
    >
      <VStack
        mt="3rem"
        spacing={6}
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        maxWidth="400px"
        align="center"
      >
        <Text fontSize="xl" fontWeight="bold" color="primary" textAlign="center">
          Upload de Outros Documentos
        </Text>

        {otherDocumentOptions.map((option) => (
          <Button
            key={option}
            onClick={handleButtonClick}
            colorScheme="teal"
            width="100%"
            variant="outline"
            borderRadius="full"
            fontSize="lg"
          >
            {option}
          </Button>
        ))}
      </VStack>
    </Box>
  );
}